import React, {useEffect} from 'react';

function Index() {
  useEffect(() => {
    window.location.replace(
      'https://biggggidea.com/project/mobilnij-treningovij-tsentr-taktichni-kotiki/'
    );
  });

  return null;
}

export default Index;
